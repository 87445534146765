import { BsChevronCompactRight, BsFillTrash3Fill, BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import createTemplateBtn from '../../../assets/addaction-btn.png';
import './ViewTemplate.scss';
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useCallback, useEffect, useState } from "react";
import DynamicDropdown from "../../../components/DynamicDropdown/DynamicDropdown";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import toast from "react-hot-toast";
import { CreateTemplateApi, GetTemplateByIdApi, UpdateTemplateApi, VerifyTemplateUpdate } from "../../../services/TemplateManagement.service";
import DynamicDropdownUpdate from "../../../components/DynamicDropdownUpdate/DynamicDropdownUpdate";
import editbtn from '../../../assets/edit-btn.png';
import NotificationModal from "../../../components/ConfirmationModal/ConfirmationModal";


interface Item {
    id: string;
    item_id:string,
    columnName: string;
    columnType: string;
    columnAttributes: Array<any>;
    toggleValue:boolean;
    is_delete:boolean,
  }


  const getItems = (count: number): Item[] => 
  Array.from({ length: count }, (_, k) => ({
    id: `item-${k}`,
    item_id:'',
    columnName: '',
    columnType: '',
    columnAttributes: [],
    toggleValue:true,
    is_delete:false,
  }));


  const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  

const grid: number = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'transparent',
    ...draggableStyle,
  });
  
  const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? 'lightblue' : '#F6F6F6',
    padding: grid,
    width: "100%",
  });

const ViewTemplate:React.FC =()=>{

    const [items, setItems] = useState<Item[]>(getItems(0));
    const [isDisabled, setIsDisabled] = useState(false);
    const [isUpdatable, setIsUpdatable] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    let { id } = useParams();
    const navigate = useNavigate()
    const [isTempActive, setIsTempActive] = useState(false);
    const [isCheckbox, setIsCheckbox] = useState(true);
    const [isActionIten, setIsActionItem] = useState(true);
    const [isComment, setIsComment] = useState(true);
    const [isPriority, setIsPriority] = useState(true);
    const [isAttachment, setIsAttachment] = useState(true);
    const [isCategory, setIsCategory] = useState(true);
    const [templateData,setTemplateData] = useState<any>();
    const [templateName,setTemplateName] = useState('');


    const [discardShow, setDiscardModalShow] = useState(false);
    const toggleDiscardModal = () => setDiscardModalShow(!discardShow);

    useEffect(()=>{

        getTemplateData()
        VerifyEditable()

    },[])


    const getTemplateData = async () => {
        const template_data = await GetTemplateByIdApi(id);
    
        if (template_data.status === 100) {
            console.log("this is template", template_data);
            setTemplateData(template_data.data);

            setIsAttachment(template_data.data.attachment_is_active == '1' ? true : false);
            setIsCheckbox(template_data.data.checkbox_is_active  == 1 ? true : false);
            setIsComment(template_data.data.comment_is_active ? true : false);
            setIsPriority(template_data.data.priority_is_active ? true : false);
            setIsActionItem(template_data.data.action_item_is_active ? true : false)
            setIsCategory(template_data.data.category_is_active ? true : false)
            setTemplateName(template_data.data.name);
            setIsTempActive(template_data.data.is_active)
            // Create a new array to hold the updated items
            const updatedItems = template_data.data && template_data.data.attributes.map((val:any, index:any) => ({
                id: `item-${index}`,
                item_id: val.id,
                columnName: val.name,
                columnType: val.type?val.type:'multiple',
                columnAttributes: val.options,
                toggleValue: val.is_active,
                is_delete:false
            }));
    
            // Set the items state once with the updated array
            setItems(updatedItems || []);
        }
    }

    const VerifyEditable=async()=>{
      const verify_edit = await VerifyTemplateUpdate(id);

      if(verify_edit.status==100){
        setIsButtonVisible(true)
      }
    }
    

    const onDragEnd = useCallback((result: DropResult) => {
        console.log("hi",result)
      if (!result.destination) {
        return;
      }
    }, []);


    
    const removeItem = (index:any,item_id:any) => {
      if(item_id==""){
        setItems(prevItems => prevItems.filter((_, i) => i !== index));
      }else{
        updateItem(index, 'is_delete', true)
      }
        
      };
    

      const addItem = () => {
        // Check if all items in the list have non-empty inputValue and selectValue
        // const allItemsValid = items.every(item => item.inputValue !== '' && item.selectValue !== '');
      
        if (validateActions()) {
          // If all items are valid, add a new item
          const newItem = {
            id: `item-${items.length}`, // Ensures a unique ID
            item_id:'',
            columnName: '',
            columnType: '',
            columnAttributes: [],
            toggleValue:true,
            is_delete:false,
          };
          setItems([...items, newItem]);
        } else {
          // If any item is invalid, you can handle it here (e.g., show an error message)
           toast.error("All addtional columns added should be filled and they shouldn't have same names as defined fields (eg: Checkbox, Action Item, Comment)")
        }
      };

      const updateItem = (index: number, field: string, value: any) => {
        console.log(value)
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [field]:  value  };
        setItems(newItems);
      };
      const discardForm=()=>{
        getTemplateData()
        setIsUpdatable(false)
        setDiscardModalShow(false)

      }

      const validateActions = () => {
        console.log("this is validated", items);
        const allItemsValid = items.every(item => 
            item.columnName !== '' && 
            item.columnType !== '' && 
            (item.columnType !== "multiple" && item.columnType !== "radio" || item.columnAttributes.length > 0) && item.columnName!=="Comment" && item.columnName!=="Action Item"
        );
        return allItemsValid;
    };


      const submitData=async()=>{
        console.log(validateActions())
        if(!validateActions()){
       return   toast.error("All addtional columns added should be filled and they shouldn't have same names as defined fields (eg: Checkbox, Action Item, Comment)")
        }
  

          const valuesArray = items.map(item => {
            const originalItem = templateData.attributes.find((attr:any) => attr.id === item.item_id);
        
                  // Remove duplicates: Keep elements from item.columnAttributes that are not in originalItem?.options
        let uniqueColumnAttributes = item.columnAttributes.filter(attr => 
          !originalItem?.options.some((originalAttr:any) => JSON.stringify(attr) === JSON.stringify(originalAttr))
      );

      // Compare columnAttributes to identify changes
      const columnAttributesChanged = JSON.stringify(uniqueColumnAttributes) !== JSON.stringify(originalItem?.options);

      if (originalItem && item.columnName === originalItem.name) {
          return {
              id: item.item_id ? item.item_id : '',
              type: item.columnType,
              options: columnAttributesChanged ? uniqueColumnAttributes : originalItem?.options,
              is_active: item.toggleValue ? 1 : 0,
              is_delete:item.is_delete
          };
      } else {
          return {
              id: item.item_id ? item.item_id : '',
              name: item.columnName,
              type: item.columnType,
              options: columnAttributesChanged ? uniqueColumnAttributes : originalItem?.options,
              is_active: item.toggleValue ? 1 : 0,
              is_delete:item.is_delete
          };
      }
  });

        
          
        let updatetemplateData:Partial<any>={
            name:templateName,
            attachment_is_active:isAttachment,
            priority_is_active:isPriority,
            comment_is_active:isComment,
            action_item_is_active:isActionIten,
            checkbox_is_active:isCheckbox,
            is_active:isTempActive,
            attributes:valuesArray

        }

        if(updatetemplateData.name==templateData.name){
            delete updatetemplateData.name;
        }
            console.log(updatetemplateData)
        setIsDisabled(true)
        const create_response = await UpdateTemplateApi(updatetemplateData,id)
        if(create_response){
            setIsDisabled(false)
            if(create_response.status==100){
                setIsUpdatable(false)
                toast.success("Successfully updated template")
                discardForm()
            }else{
                toast.error("Error updating template: "+create_response.message)
            }
        }
      }



    return(<div className='main-content  fade-in'>


<div className="d-flex" >
            <p className="breadcrumb pe-2">Management</p>
            <BsChevronCompactRight/>
            <p onClick={()=>navigate('/template-management')} className="breadcrumb ps-2 pe-2">Template Management</p>
            <BsChevronCompactRight/>
            <p className="breadcrumb ps-2">{templateName}</p>
          </div>

<div className="d-flex justify-content-between">
<div className="d-flex pt-4 pb-4">
    <div className="name-square"> {templateData && templateData.name.charAt(0)}</div>
    {!isUpdatable?<span className="templatename">{templateData && templateData.name}</span>:<input value={templateName} onChange={(e)=>setTemplateName(e.target.value)} className="templatename-input" />}
</div>

{!isUpdatable && isButtonVisible && <div className="pt-4 pb-4">
      <button type="button" className="register-modal-btn" onClick={()=>setIsUpdatable(!isUpdatable)}> <span className='pe-2'><img src={editbtn} /></span>
Edit Template      </button>

    </div>}
</div>

{isUpdatable &&         
<div className="d-flex justify-content-between">
<div className="mb-3">
      <button onClick={()=>addItem()} type="button" className="create-template-btn"> <span className='pe-2'><img src={createTemplateBtn} /></span>
Add New Column      </button></div>
<div className="d-flex align-items-center">       <div className="form-check form-switch">
                <label  className='active-txt'>Active</label>

                    <input   checked={isTempActive} onChange={()=>setIsTempActive(!isTempActive)}  className="form-check-input active-user" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    </div>
                </div>
</div>}




<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
          className="mb-5"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >

<div className="m-3">
            <div className="row mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Checkbox'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Checkbox'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isCheckbox}
                             onChange={()=>setIsCheckbox(!isCheckbox)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              disabled={!isUpdatable}
                            />
                          </div>
                         
                        </div>
            </div>



            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Category'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Short Text'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isActionIten}
                             onChange={()=>setIsCategory(!isCategory)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              disabled={!isUpdatable}
                            />
                          </div>
                         
                        </div>
            </div>


            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Action items'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Short Text'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isActionIten}
                             onChange={()=>setIsActionItem(!isActionIten)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              disabled={!isUpdatable}
                            />
                          </div>
                         
                        </div>
            </div>



            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Comment'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Long Text'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isComment}
                             onChange={()=>setIsComment(!isComment)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              disabled={!isUpdatable}
                            />
                          </div>
                         
                        </div>
            </div>


            <div className="row mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Priority'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Dropdown'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'High / Medium / Low'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0" >
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isPriority} 
                             onChange={()=>setIsPriority(!isPriority)}
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                                disabled={!isUpdatable}
                            />
                          </div>
                         
                        </div>
            </div>


            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Attachment'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'File'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isAttachment} 
                             onChange={()=>setIsAttachment(!isAttachment)}
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              disabled={!isUpdatable}
                            />
                          </div>
                         
                        </div>
            </div>

            </div>
            {items.map((item, index) => (
          (item.is_delete==false &&    <Draggable isDragDisabled={!isUpdatable} key={item.id} draggableId={item.id} index={index}>
               {(provided, snapshot) => (
                    <div
                  
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                   
                      <div className="row justify-content-around ">
                        <div className="col-6 col-md-4 col-lg-4 d-flex">
                            <div className="d-flex justify-content-center align-items-center pe-2">
                        <BsThreeDotsVertical />
                        </div>
                          <input 
                            placeholder="Enter column name" 
                            className="checklist-input me-1" 
                            value={item.columnName} 
                            onChange={(e) => updateItem(index, 'columnName', e.target.value)}
                            disabled={!isUpdatable}
                          />
                        </div>
                        <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">
                          <select 
                            className="checklist-select" 
                            disabled={!isUpdatable}
                            value={item.columnType} 
                            onChange={(e) => updateItem(index, 'columnType', e.target.value)} 
                          >
                            <option value="">Select</option>
                            <option value="short">Short Text</option>
                            <option value="long">Long Text</option>
                            <option value="multiple">Multiple option</option>
                            <option value="radio">Radio option</option>
                            <option value="attachment">Attachment</option>
                          </select>
                        </div>


                        <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                        {/* <input 
                            placeholder="Enter column name" 
                            className="checklist-input me-1" 
                            value={item.columnName} 
                            onChange={(e) => updateItem(index, 'columnName', e.target.value)}
                          /> */}

                          {/* {    (items[index].columnType!="multiple" || items[index].columnType!="radio" ) &&            <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                } */}

                         {(items[index].columnType=="multiple" || items[index].columnType=="radio" ) ? <DynamicDropdownUpdate  onOptionsChange ={(newOptions:any) => updateItem(index, 'columnAttributes', newOptions)} values={item.columnAttributes} isDisabled ={isUpdatable}/>:<input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />}
                        </div>

                       
                        <div className={`col-6  justify-content-between col-md-2 col-lg-2 mt-3 mt-md-0 mt-lg-0 toggle-background ${items.length>1?'d-flex':''}`}>
                          
                          <div className="d-flex justify-content-center align-items-center  p-2">

                          <div className=" form-check form-switch ">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={item.toggleValue} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              id={`flexSwitchCheckDefault-${index}`} 
                              disabled={!isUpdatable}
                              onChange={(e) => updateItem(index, 'toggleValue', e.target.checked)}
                            />
                          </div>
                          </div>
         

                          {items.length>1 && isUpdatable && <div className=" d-flex align-items-center" style={{cursor:'pointer'}}>
                           <span onClick={()=>removeItem(index,item.item_id)}><BsFillTrash3Fill/></span>
                        </div>}
                         
                        </div>

           
                      </div>

            

                      
                    </div>
                  )}
              </Draggable>)
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>


{isUpdatable &&    <div className="pt-3 pb-4 d-flex justify-content-end">
    <button onClick={()=>toggleDiscardModal()} className="discard-btn me-3">Discard</button>
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={submitData}
        is_loading={isDisabled}
        classname="editsave-btn"
      />
</div>}
        


<NotificationModal
        show={discardShow}
        toggleModal={toggleDiscardModal}
        title="Create User Account"
      >
       <p className='delete-confirmation'> Are you sure you want to discard all changes ?</p>

        <div className='d-flex justify-content-center'>
        <button onClick={()=>discardForm()} className="delete-btn-confirm me-3 mt-3">Yes</button>
        <button onClick={()=>toggleDiscardModal()} className="no-btn-confirm me-3 mt-3">No</button>
        </div>
      </NotificationModal>
    </div>);
}

export default ViewTemplate;


