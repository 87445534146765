import { BsChevronCompactRight, BsFillTrash3Fill, BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import createTemplateBtn from '../../../assets/addaction-btn.png';
import './CreateTemplate.scss';
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useCallback, useState } from "react";
import DynamicDropdown from "../../../components/DynamicDropdown/DynamicDropdown";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import toast from "react-hot-toast";
import { CreateTemplateApi } from "../../../services/TemplateManagement.service";
import NotificationModal from "../../../components/ConfirmationModal/ConfirmationModal";



interface Item {
    id: string;
    columnName: string;
    columnType: string;
    columnAttributes: Array<any>;
    toggleValue:boolean;
  }


  const getItems = (count: number): Item[] => 
  Array.from({ length: count }, (_, k) => ({
    id: `item-${k}`,
    columnName: '',
    columnType: '',
    columnAttributes: [],
    toggleValue:true
  }));


  const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  

const grid: number = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'transparent',
    ...draggableStyle,
  });
  
  const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? 'lightblue' : '#F6F6F6',
    padding: grid,
    width: "100%",
  });

const CreateTemplate:React.FC =()=>{

    const [items, setItems] = useState<Item[]>(getItems(0));
    const [isDisabled, setIsDisabled] = useState(false);
    let { id } = useParams();
    const navigate = useNavigate()

    const [isCheckbox, setIsCheckbox] = useState(true);
    const [isActionIten, setIsActionItem] = useState(true);
    const [isComment, setIsComment] = useState(true);
    const [isPriority, setIsPriority] = useState(true);
    const [isAttachment, setIsAttachment] = useState(true);
    const [isCategory, setIsCategory] = useState(false);

    const [discardShow, setDiscardModalShow] = useState(false);
    const toggleDiscardModal = () => setDiscardModalShow(!discardShow);

    const onDragEnd = useCallback((result: DropResult) => {
      if (!result.destination) {
          return;
      }
  
      // Use the reorder function to rearrange items
      const reorderedItems = reorder(
          items,
          result.source.index,
          result.destination.index
      );
  
      // Update the state with the reordered items
      setItems(reorderedItems);
  }, [items]);
  


    
    const removeItem = (index:any) => {
        setItems(prevItems => prevItems.filter((_, i) => i !== index));
      };
    

      const addItem = () => {
        // Check if all items in the list have non-empty inputValue and selectValue
        // const allItemsValid = items.every(item => item.inputValue !== '' && item.selectValue !== '');
      
        if (validateActions()) {
          // If all items are valid, add a new item
          const newItem = {
            id: `item-${items.length}`, // Ensures a unique ID
            columnName: '',
            columnType: '',
            columnAttributes: [],
            toggleValue:true
          };
          setItems([...items, newItem]);
        } else {
          // If any item is invalid, you can handle it here (e.g., show an error message)
           toast.error("All addtional columns added should be filled and they shouldn't have same names as defined fields (eg: Checkbox, Action Item, Comment)")
        }
      };

      const updateItem = (index: number, field: string, value: any) => {
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [field]: value };
        setItems(newItems);
      };
      const discardForm=()=>{
        setItems([])
        setIsActionItem(true)
        setIsCheckbox(true)
        setIsAttachment(true)
        setIsComment(true)
        setIsPriority(true)
        setIsCategory(true)
      }


      const discardAllChanges=()=>{
        discardForm()
        navigate('/template-management')
      }

      const validateActions = () => {
        console.log("this is validated", items);
        const allItemsValid = items.every(item => 
            item.columnName !== '' && 
            item.columnType !== '' && 
            (item.columnType !== "multiple" && item.columnType !== "radio" || item.columnAttributes.length > 0) && item.columnName!=="Comment" && item.columnName!=="Action Item"
        );
        return allItemsValid;
    };


      const submitData=async()=>{
        if(!validateActions()){
            return toast.error("All addtional columns added should be filled and they shouldn't have same names as defined fields (eg: Checkbox, Action Item, Comment)")
          }
  

        const valuesArray = items.map(item => ({
            name: item.columnName,
            type: item.columnType,
            options:item.columnType=="multiple" || item.columnType=="radio"?item.columnAttributes:[],
            is_active:item.toggleValue?1:0
          }));
        
          
        let templateData={
            name:id,
            attachment_is_active:isAttachment,
            priority_is_active:isPriority,
            comment_is_active:isComment,
            action_item_is_active:isActionIten,
            category_is_active:isCategory,
            checkbox_is_active:isCheckbox,
            is_active:true,
            attributes:valuesArray

        }
            console.log(templateData)
        setIsDisabled(true)
        const create_response = await CreateTemplateApi(templateData)
        if(create_response){
            setIsDisabled(false)
            if(create_response.status==100){
                toast.success("Successfully created template")
                discardForm()
                navigate('/view-template/'+create_response.data.template_id)
            }else{
                toast.error("Error creating template: "+create_response.message)
            }
        }
      }
      
    return(<div className='main-content  fade-in'>


<div className="d-flex" >
            <p className="breadcrumb pe-2">Management</p>
            <BsChevronCompactRight/>
            <p onClick={()=>navigate('/template-management')} className="breadcrumb ps-2 pe-2">Template Management</p>
            <BsChevronCompactRight/>
            <p className="breadcrumb ps-2">{id}</p>
          </div>



          <div className="mb-3">
      <button onClick={()=>addItem()} type="button" className="create-template-btn"> <span className='pe-2'><img src={createTemplateBtn} /></span>
Add New Column      </button></div>




<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >

<div className="m-3">
            <div className="row mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Checkbox'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Checkbox'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isCheckbox}
                             onChange={()=>setIsCheckbox(!isCheckbox)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              
                            />
                          </div>
                         
                        </div>
            </div>



            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Category'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Short Text'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isCategory}
                             onChange={()=>setIsCategory(!isCategory)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                
                            />
                          </div>
                         
                        </div>
            </div>


            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Action items'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Short Text'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isActionIten}
                             onChange={()=>setIsActionItem(!isActionIten)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              
                            />
                          </div>
                         
                        </div>
            </div>



            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Comment'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Long Text'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isComment}
                             onChange={()=>setIsComment(!isComment)} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                
                            />
                          </div>
                         
                        </div>
            </div>


            <div className="row mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Priority'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Dropdown'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'High / Medium / Low'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isPriority} 
                             onChange={()=>setIsPriority(!isPriority)}
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                
                            />
                          </div>
                         
                        </div>
            </div>


            <div className="row  mb-4">
                <div className="col-6 col-md-4 col-lg-4 d-flex">
                <div  className="d-flex justify-content-center align-items-center pe-2">
                        < BsThreeDotsVertical style={{color:'transparent'}}/>
                        </div>

                        
            <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'Attachment'} disabled />

                            
                </div>


                <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">

                <input           placeholder="Enter column name" 
                            className="checklist-input me-1" value={'File'} disabled />
                </div>


                <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                </div>
                


                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center toggle-background mt-3 mt-md-0 mt-lg-0">
                          <div className="form-check form-switch">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={isAttachment} 
                             onChange={()=>setIsAttachment(!isAttachment)}
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                
                            />
                          </div>
                         
                        </div>
            </div>

            </div>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
               {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                   
                      <div className="row justify-content-around">
                        <div className="col-6 col-md-4 col-lg-4 d-flex">
                            <div className="d-flex justify-content-center align-items-center pe-2">
                        <BsThreeDotsVertical />
                        </div>
                          <input 
                            placeholder="Enter column name" 
                            className="checklist-input me-1" 
                            value={item.columnName} 
                            onChange={(e) => updateItem(index, 'columnName', e.target.value)}
                          />
                        </div>
                        <div className="col-6 col-md-3 col-lg-3 mt-0 mt-md-0 mt-lg-0">
                          <select 
                            className="checklist-select" 
                            value={item.columnType} 
                            onChange={(e) => updateItem(index, 'columnType', e.target.value)} 
                          >
                            <option value="">Select</option>
                            <option value="short">Short Text</option>
                            <option value="long">Long Text</option>
                            <option value="multiple">Multiple option</option>
                            <option value="radio">Radio option</option>
                            <option value="attachment">Attachment</option>
                          </select>
                        </div>


                        <div className="col-6 col-md-3 col-lg-3 d-flex mt-3 mt-md-0 mt-lg-0">
                        {/* <input 
                            placeholder="Enter column name" 
                            className="checklist-input me-1" 
                            value={item.columnName} 
                            onChange={(e) => updateItem(index, 'columnName', e.target.value)}
                          /> */}

                          {/* {    (items[index].columnType!="multiple" || items[index].columnType!="radio" ) &&            <input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />
                } */}

                         {(items[index].columnType=="multiple" || items[index].columnType=="radio" ) ? <DynamicDropdown  onOptionsChange ={(newOptions:any) => updateItem(index, 'columnAttributes', newOptions)} />:<input           placeholder="Enter column name" 
                            className="checklist-input me-1"  value={'Not applicable'} disabled />}
                        </div>

                       
                        <div className={`col-6  justify-content-between col-md-2 col-lg-2 mt-3 mt-md-0 mt-lg-0  ${items.length>1?'d-flex':''}`}>
                          
                          <div className="d-flex justify-content-center align-items-center toggle-background p-2">

                          <div className=" form-check form-switch ">
                            <label className='active-txt'>Active</label>
                            <input 
                             checked={item.toggleValue} 
                              className="form-check-input active-user" 
                              type="checkbox" 
                              role="switch" 
                              id={`flexSwitchCheckDefault-${index}`} 
                              onChange={(e) => updateItem(index, 'toggleValue', e.target.checked)}
                            />
                          </div>
                          </div>
         

                          {items.length>1 && <div className=" d-flex align-items-center" style={{cursor:'pointer'}}>
                           <span onClick={()=>removeItem(index)}><BsFillTrash3Fill/></span>
                        </div>}
                         
                        </div>

           
                      </div>

            

                      
                    </div>
                  )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>


    <div className="pt-3 pb-4 d-flex justify-content-end">
    <button onClick={()=>toggleDiscardModal()} className="discard-btn me-3">Discard</button>
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={submitData}
        is_loading={isDisabled}
        classname="editsave-btn"
      />
</div>
        

<NotificationModal
        show={discardShow}
        toggleModal={toggleDiscardModal}
        title="Create User Account"
      >
       <p className='delete-confirmation'> Are you sure you want to discard all changes ?</p>

        <div className='d-flex justify-content-center'>
        <button onClick={()=>discardAllChanges()} className="delete-btn-confirm me-3 mt-3">Yes</button>
        <button onClick={()=>toggleDiscardModal()} className="no-btn-confirm me-3 mt-3">No</button>
        </div>
      </NotificationModal>


    </div>);
}

export default CreateTemplate;