import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Left-side.scss'; // Make sure to create a MenuBar.css file with the CSS provided below
import homeIco from '../../assets/home-icon.png'
import homeIco2 from '../../assets/home-icon2.png'
import userIco from '../../assets/user-icon2.png'
import userIco3 from '../../assets/user-icon3.png'
import dropdownup from '../../assets/dropdown-up.png'
import dropdowndown from '../../assets/dropdown-down.png'

import property from '../../assets/property-Icon.png'
import password from '../../assets/password-Icon.png'
import checklist from '../../assets/checklist-Icon.png'
import settings from '../../assets/settings-ico.png'

type MenuProps ={
  type:any;
  isOpen?:any;
}
const MenuBar = ({type,isOpen}:MenuProps) => {
  console.log("ffffffffffffffffffffffff",type)
  // States to handle the visibility of dropdown menus
  const [isManagementDropdownVisible, setManagementDropdownVisible] = useState(false);
  const [isSettingsDropdownVisible, setSettingsDropdownVisible] = useState(false);
  const path = window.location.pathname;

  const toggleManagementDropdown = () => {
    setManagementDropdownVisible(!isManagementDropdownVisible);
  };


  const toggleSettingsDropdown = () => {
    setSettingsDropdownVisible(!isSettingsDropdownVisible);
  };

  return (
    <nav className={`sidebar ${isOpen? 'sidebar-open' : ''}`}>
  
      <ul className="sidebar-nav">
        <li  className={`nav-item home p-1 ${path === '/' ? 'active' : ''}`}>
          <Link to="/" className="nav-links">
            <div>
            <span><img className='pe-3' src={path==="/"?homeIco2:homeIco} /></span>
            <span className="link-text">Home</span>
            </div>
         
            <span style={{display:"none"}} className=" notification">3</span>
            
          </Link>
        </li>

        <li  className={`nav-item home p-1 ${path === '/dashboard' ? 'active' : ''}`}>
          <Link to="/dashboard" className="nav-links">
            <div>
            {/* <span><img className='pe-3' src={path==="/dashboard"?homeIco2:homeIco} /></span> */}
            <span className="link-text">Dashboard</span>
            </div>
         
            <span style={{display:"none"}} className=" notification">3</span>
            
          </Link>
        </li>

        <div style={{background:"white"}}>
        <li className="nav-item ">
          <div className="nav-links" onClick={toggleManagementDropdown}>
          <div>
          <span><img className='pe-3' src={userIco} /></span>
            <span className="link-text">Management</span>
            </div>
            <div className='d-flex'>
            <span  style={{display:"none"}}  className=" notification">1</span>
            <span className="dropdown-arrow">{isManagementDropdownVisible ? <img className='ps-2' src={dropdownup} /> : <img className='ps-2' src={dropdowndown} />}</span>
            </div>
          </div>
          {isManagementDropdownVisible && (
            <ul className="dropdown ps-2">

            

              {(type=='admin' || type == 'super') &&      <li className={`dropdown-item ${path === '/template-management' || path.includes('/create-template/') ||  path.includes('/view-template/')? 'active' : ''}`}>
              <span><img className='pe-3' src={checklist} /></span>
                <Link to="/template-management" className="dropdown-nav-links">Template Management</Link>
              </li>}      

              {type=='super' &&      <li className={`dropdown-item ${path === '/company'? 'active' : ''}`}>
              <span><img className='pe-3' src={checklist} /></span>
                <Link to="/company" className="dropdown-nav-links">Company</Link>
              </li>}    

        {(type=='admin' || type == 'user' || type == 'super') &&      <li className={`dropdown-item ${path === '/checklist-management' || path=== '/checklist-management/create-checklist' ? 'active' : ''}`}>
              <span><img className='pe-3' src={checklist} /></span>
                <Link to="/checklist-management" className="dropdown-nav-links">Checklist Management</Link>
              </li>}
             
            </ul>
          )}
        </li>
        {/* More nav items */}
        <li className="nav-item">
          {/* <Link to="/settings" className="nav-links">
          <span><img className='pe-3' src={settings} /></span>
            <span className="link-text">Settings</span>
            <span className="dropdown-arrow">{isSettingsDropdownVisible ? <img className='ps-2' src={dropdownup} /> : <img className='ps-2' src={dropdowndown} />}</span>

          </Link> */}



<div className="nav-links" onClick={toggleSettingsDropdown}>
          <div>
          <span><img className='pe-3' src={settings} /></span>
            <span className="link-text">Settings</span>
            </div>
            <div className='d-flex'>
            <span  style={{display:"none"}}  className=" notification">1</span>
            <span className="dropdown-arrow">{isSettingsDropdownVisible ? <img className='ps-2' src={dropdownup} /> : <img className='ps-2' src={dropdowndown} />}</span>
            </div>
          </div>
          {isSettingsDropdownVisible && (
            <ul className="dropdown ps-2">
      {type !== 'user' &&       <li className={`dropdown-item ${path === '/user-management' ? 'active' : ''}`}>
                <span><img className='pe-3' src={userIco3} /></span>
                <Link to="/user-management" className="dropdown-nav-links">User Management</Link>
              </li>}
      {type=='admin' &&       <li className={`dropdown-item ${path === '/property-management' ? 'active' : ''}`}>
              <span><img className='pe-3' src={property} /></span>
                <Link to="/property-management" className="dropdown-nav-links">Property Management</Link>
              </li>}
              <li className={`dropdown-item ${path === '/password-management' ? 'active' : ''}`}>
              <span><img className='pe-3' src={password} /></span>
                <Link to="/password-management" className="dropdown-nav-links">Password Management</Link>
              </li>

             
            </ul>
          )}

          
        </li>


        <li  className={`nav-item home p-1 ${path === '/support' ? 'active' : ''}`}>
          <Link to="/support" className="nav-links">
            <div>
            <span><img className='pe-3' src={path==="/support"?property:property} /></span>
            <span className="link-text">Support Documents</span>
            </div>
         
            <span style={{display:"none"}} className=" notification">3</span>
            
          </Link>
        </li>
        </div>
      </ul>
    </nav>
  );
};

export default MenuBar;
