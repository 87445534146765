import { CustomAuthorizedApiCall, apiCall, authorizedapiCall } from "../utils/api_util.service";


export const GetUsersApi = async (keyword:any="") => {
    try {
      const data = await authorizedapiCall('user/?'+keyword);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }


  export const UpdateUsersApi = async (id:any,payload:any) => {
    try {
      const data = await authorizedapiCall('user/'+id+'/','PUT',payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
  export const CompanyApi = async (company_payload:any) => {
    try {
      const data = await authorizedapiCall('company/','POST',company_payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
}


export const CompanyGetApi = async (search:any="") => {
  try {
    const data = await authorizedapiCall('company/?'+search);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }


}
  export const CreateCompanyUsersApi = async (company_payload:any) => {
    try {
      const data = await authorizedapiCall('company/','POST',company_payload);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
}


export const CompanyUsersGetApi = async (search:any="") => {
  try {
    const data = await authorizedapiCall('company/?'+search);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
}
