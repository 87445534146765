import { useEffect, useState } from 'react';
import './Company.scss';
import { Table2 } from '../../components/Table2/Table';
import { BsChevronCompactRight } from 'react-icons/bs';
import registerbtn from '../../assets/register-btn.png'
import ReusableModal from '../../components/Modal/Modal';
import { CreateCompany } from './CreateCompany/CreateCompany';
import { jsonToUrlParams } from '../../utils/json_to_params_util.service';
import { CompanyGetApi } from '../../services/UserManagement.service';
import { Table } from '../../components/Table/Table';




export const  Company = () =>{
    var tablePageIndex = 1
    const [currentPage, setCurrentPage] = useState(tablePageIndex);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [dataCount, setDataCount] = useState<number>(0);
    const headers = ['Name','Status','Action'];
    const tableData = [{Name:"William James",email_address:"address",status:"active",roles:"adadad",Action:"scscsc",age:"10"}]
    const keys = ['name', 'status'];

    const [propertyViewModalShow, setPropertyViewModalShow] = useState(false);
    const togglePropertyViewModal = () => setPropertyViewModalShow(!propertyViewModalShow);

    const [companyData, setCopanyData] = useState([]);

    const handleActionClick = (rowData: { [key: string]: any }) => {
        // handle the click event
        console.log('Button clicked for:', rowData);

      };

      useEffect(() => {
        GetCompanies();
    }, []);
    

      const GetCompanies= async()=>{
        let params = {
          page: tablePageIndex,
          limit: itemsPerPage,

      };
          
          const property_data = await CompanyGetApi(jsonToUrlParams(params));
          console.log(property_data)
          let data_array:any=[]
          if(property_data.status==100){
              setDataCount(property_data.count)
              for(let i=0;i<property_data.data.length;i++){
                  console.log("vadad")
  
                  let data_json=  {
                      "id": property_data.data[i].id,
                      "name": property_data.data[i].name,
                      "status":property_data.data[i].is_active?"Active":"InActive",
                      "created_by_id": 3,
                      "created_on": "2023-11-10 06:33:06"
                  }
      
                  data_array= [data_json,...data_array]
  
              }
  
              setCopanyData(data_array)
              
          }
          
      }
      

      const closeCreateProperty=()=>{
        GetCompanies()
        setPropertyViewModalShow(false)
    }

    const renderActionButton = (row: { [key: string]: any }) => {

        return <div className='d-flex'>
        <button className='view-btn ms-2' onClick={()=>console.log()}>Update</button>
     </div>;
    
    };
    return(
        <div className='main-content  fade-in'>
                   <div className="d-flex">
                <p className="breadcrumb pe-2">Management</p>
                <BsChevronCompactRight/>
                <p className="breadcrumb ps-2">Company</p>
            </div>


            <div className='pb-4 pt-4  d-flex justify-content-end'>
                {/* <div>
                    <input value={search} onChange={(e)=>setSearchData(e.target.value)} className='search-input' placeholder='Search Here' />
                </div> */}

                <div>
      <button type="button" className="register-modal-btn" onClick={togglePropertyViewModal}> <span className='pe-2'><img src={registerbtn} /></span>
Create Company      </button>

    </div>


            </div>
            
            <Table headers={headers} data={companyData} renderButton={renderActionButton} keys={keys} isActionButton={true} navigation='/view-company/'/>


            <ReusableModal
        show={propertyViewModalShow}
        toggleModal={togglePropertyViewModal}
        title="Create Company"
      >
        <CreateCompany updateData={()=>closeCreateProperty()}/>
      </ReusableModal>
        </div>
    )
}