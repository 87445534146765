

import registerbtn from '../../../assets/register-btn.png'
import { BsChevronCompactRight } from "react-icons/bs";
import { Table } from '../../../components/Table/Table';
import { useEffect, useState } from 'react';
import { GetUsersApi } from '../../../services/UserManagement.service';
import ReusableModal from '../../../components/Modal/Modal';
import Register from '../../Authentication/Register/Register';
// import { ViewUsers } from './../../ViewUsers/ViewUsers';
// import { EditUsers } from './EditUsers/EditUsers';
import Pagination from '../../../components/Pagination/Pagination';
import resetbtn from "../../../assets/load.png";
import applybtn from "../../../assets/apply-btn.png"
import { Table2 } from '../../../components/Table2/Table';
import { useParams } from 'react-router-dom';
export const ViewCompany = () =>{
    let { id } = useParams();
    var tablePageIndex = 1
    const [currentPage, setCurrentPage] = useState(tablePageIndex);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [dataCount, setDataCount] = useState<number>(0);
    const headers = ['Name', 'Email', 'Status', 'Role'];
    const tableData = [{Name:"William James",email_address:"address",status:"active",roles:"adadad",Action:"scscsc",age:"10"}]
    const keys = ['name', 'email', 'status', 'role'];

    const [userData, setUserData] = useState<any>();
    const [IndividualuserData, setIndividualuserData] = useState<any>();
    const [modalShow, setModalShow] = useState(false);
    const toggleModal = () => setModalShow(!modalShow);

    const [editModalShow, setEditModalShow] = useState(false);
    const toggleEditModal = () => setEditModalShow(!editModalShow);

    const [viewModalShow, setViewModalShow] = useState(false);
    const toggleViewModal = () => setViewModalShow(!viewModalShow);

    const [search, setSearchData] = useState('');

    const [searchName, setSearchName] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchContact, setSearchContact] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [isReset, SetisReset] = useState(false);

    useEffect(() => {
        GetUsers();
    }, [search]);

    useEffect(() => {
      GetUsers();
    }, [isReset]);


    function jsonToUrlParams(json: any) {
      const params = new URLSearchParams();
      for (const key in json) {
          if (json.hasOwnProperty(key)) {
              params.append(key, json[key]);
          }
      }
      return params.toString();
  }
    const GetUsers= async()=>{
      let params = {
        page: tablePageIndex,
        limit: itemsPerPage,
        name:searchName,
        contact_no:searchContact,
        email:searchEmail,
        is_active:searchStatus,
        company_id:id
    };
        const users_data = await GetUsersApi(jsonToUrlParams(params));
        console.log(users_data)
        let data_array:any=[]
        if(users_data.status==100){
          setDataCount(users_data.count)
            for(let i=0;i<users_data.data.length;i++){
          
                let data_json={
                    id:users_data.data[i].id,
                    name:users_data.data[i].first_name + " "+users_data.data[i].last_name,
                    email:users_data.data[i].email,
                    role:users_data.data[i].is_admin?"Admin":users_data.data[i].is_super_admin?"Super Admin":users_data.data[i].is_user?"User":"N/A",
                    status:users_data.data[i].is_active? "Active":"InActive",
                    first_name:users_data.data[i].first_name,
                    last_name:users_data.data[i].last_name,
                    property:users_data.data[i].property_name,
                    property_id:users_data.data[i].properties,
                    is_locked:users_data.data[i].is_locked,
                    is_verified:users_data.data[i].is_verified,
                }
                data_array= [...data_array,data_json]

            }
            console.log("vadad",data_array)
             setUserData(data_array)
            
        }
        
    }

    const handleActionClick = (rowData: { [key: string]: any }) => {
        // handle the click event
        console.log('Button clicked for:', rowData);
        setViewModalShow(true)
        setIndividualuserData(rowData)
      };
    
    const renderActionButton = (row: { [key: string]: any }) => {
    
        return <button className='view-btn' onClick={() => handleActionClick(row)}>View</button>;
    
    };

    const openEdit=()=>{
        console.log("clicked")
        setViewModalShow(false)
        setEditModalShow(true)
    }

    const closeEdit=()=>{
      setEditModalShow(false)
      GetUsers()
  }

  const closeRegister=()=>{
    setModalShow(false)
    GetUsers()
}

const handlePageChange = async (pageNumber: any) => {
  await setCurrentPage(pageNumber);
  tablePageIndex = pageNumber
  GetUsers()
};

const SearchUsingFilters=()=>{
  GetUsers()
}

const resetBtn=()=>{
setSearchEmail('')
setSearchName('')
setSearchContact('')
setSearchStatus('')
SetisReset(!isReset)

}
    return(
        <div className='main-content  fade-in'>
            <div className="d-flex">
                <p className="breadcrumb pe-2">Management</p>
                <BsChevronCompactRight/>
                <p className="breadcrumb ps-2">Company Users</p>
            </div>

            <div className='pb-4 pt-4  d-flex justify-content-end'>
                {/* <div>
                    <input value={search} onChange={(e)=>setSearchData(e.target.value)} className='search-input' placeholder='Search Here' />
                </div> */}

                <div>
      <button type="button" className="register-modal-btn" onClick={toggleModal}> <span className='pe-2'><img src={registerbtn} /></span>
Create User      </button>

    </div>


            </div>


            <div className="filter-box mt-3">
        <div className="d-flex justify-content-between pb-2">
          <div className="row w-100">
            {/* {favourites.map((value)=>(
              <div className="col-2 me-lg-0 me-md-0 me-5"><span className="recently-viewed">{value} <BsX className="recent-cross" /></span></div>
            ))} */}

          </div>

  
        </div>


        <div className="row filter-margin">
          {/* <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Property Name</label>
              <input value={searchName} onChange={(e)=>setSearchName(e.target.value)} className="mt-1 filter-select" placeholder="Enter name" />

          </div> */}

            {/* user searchable checklist */}
          <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Contact No</label>
              <input value={searchContact} onChange={(e)=>setSearchContact(e.target.value)} className="mt-1 filter-select" placeholder="Enter Contact no" />

          </div>

          {/* property searchable checklist */}
          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">Email</label>
              <input value={searchEmail} onChange={(e)=>setSearchEmail(e.target.value)} className="mt-1 filter-select" placeholder="Enter Email" />

          </div>


          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">State</label>
              <select value={searchStatus} onChange={(e)=>setSearchStatus(e.target.value)} className=" mt-1 filter-select">
              <option value="">Select status</option>
                <option value="1">Active</option>
                <option value="0">In Active</option>
     
              </select>
          </div>

          <div className="col-6 col-md-3 col-lg-3 d-flex align-items-center">
          <div className="d-flex align-items-center">
      <button onClick={()=>SearchUsingFilters()} type="button" className="apply-btn me-2 " > <span className='pe-2'><img src={applybtn} /></span>
Apply     </button>

<span onClick={()=>resetBtn()} style={{cursor:'pointer'}}><img width={15} className="img-fluid" src={resetbtn}/></span>

        </div>
            </div>  
        </div>
      </div>

            <Table2 headers={headers} data={userData} keys={keys} isActionButton={true} navigation='ssf' openModal={(e) => handleActionClick(e)}/>


            <Pagination itemsPerPage={itemsPerPage} count={dataCount} currentPage={currentPage} onPageChange={handlePageChange} />

    <div>
    <ReusableModal
        show={viewModalShow}
        toggleModal={toggleViewModal}
        title="Create User Account"
      >
    {/* <ViewUsers user_data={IndividualuserData} openEdit={openEdit}/> */}
    <p>sfds</p>
      </ReusableModal>
    </div>


    <div>
    <ReusableModal
        show={editModalShow}
        toggleModal={toggleEditModal}
        title="Create User Account"
      >
        <p>sfds</p>
    {/* <EditUsers user_data={IndividualuserData} updateGet={closeEdit}/> */}
      </ReusableModal>
    </div>

    <ReusableModal
        show={modalShow}
        toggleModal={toggleModal}
        title="Create User Account"
      >
        <Register updateData={()=>closeRegister()}/>
      </ReusableModal>

        </div>
        );
}