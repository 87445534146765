import React, { useEffect, useState } from "react";
import { BsChevronCompactRight, BsX, BsXLg } from "react-icons/bs";
import "./ChecklistManagement.scss";
import { Table } from "../../components/Table/Table";
import checklistbtn from "../../assets/checklist-btn.png"
import applybtn from "../../assets/apply-btn.png"
import { useNavigate } from "react-router-dom";
import { DeleteChecklistApi, GetChecklistApi } from "../../services/ChecklistManagement.service";
import { jsonToUrlParams } from "../../utils/json_to_params_util.service";
import Pagination from "../../components/Pagination/Pagination";
import { CompanyGetApi, GetUsersApi } from "../../services/UserManagement.service";
import { GetAllPropertyApi } from "../../services/PropertyManagement.service";
import Select from 'react-select';
import resetbtn from "../../assets/load.png";
import toast from "react-hot-toast";

const ChecklistManagement : React.FC=()=>{
  const [type,settyperData] = useState(sessionStorage.getItem('user_type')||localStorage.getItem('user_type'));

  var tablePageIndex = 1
  const [currentPage, setCurrentPage] = useState(tablePageIndex);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [checklistData, setChecklistData] = useState<any>();

  const navigate = useNavigate();
  const favourites = ['Name', 'Email', 'Status', 'Role', 'Action'];


  const headers = ['Check List Name', 'Assign To', 'Status', 'Template Name', 'Property', 'Progress Bar', 'Action'];
  const tableData = [{Name:"CLN0001",assign_to:"Admin",status:"Complete",template:"Custom",Properties:"A",age:"10"}]
  const keys = ['name', 'assignee_name', 'status', 'template_name', 'property_name' ];

  const [searchName, setSearchName] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchProperty, setSearchProperty] = useState('');
  const [searchAssignee, setSearchAssignee] = useState('');
  const [searchCompany, setSearchCompany] = useState('');

  const [userData, setUserData] = useState<any>();
  const [propertyData, setPropertyData] = useState<any>();
  const [isReset, SetisReset] = useState(false);
  const [companyData, setCopanyData] = useState([]);

  useEffect(() => {
    GetChecklists();
    GetProperties()
    GetUsers()
}, []);

useEffect(() => {
  GetChecklists();
}, [isReset]);

const SearchUsingFilters=()=>{
  GetChecklists()
}

useEffect(() => {
  if(type=="super"){
    GetCompanies();
  }

}, []);


const GetCompanies= async()=>{
  let params = {
    page: tablePageIndex,
    limit: itemsPerPage,

};
    
    const property_data = await CompanyGetApi();
    console.log(property_data)
    let data_array:any=[]
    if(property_data.status==100){
        setDataCount(property_data.count)
        for(let i=0;i<property_data.data.length;i++){
            console.log("vadad")

            let data_json=  {
                "id": property_data.data[i].id,
                "name": property_data.data[i].name,
                "status":property_data.data[i].is_active?"Active":"InActive",
                "created_by_id": 3,
                "created_on": "2023-11-10 06:33:06"
            }

            data_array= [data_json,...data_array]

        }

        setCopanyData(data_array)
        
    }
    
}


const customStyles = {
  control: (provided:any, state:any) => ({
      ...provided,
      // this part handles the border color on focus
      boxShadow: state.isFocused ? 'none' : 'none',
      borderColor: state.isFocused ? 'transparent' : 'transparent',
      "&:hover": {
          borderColor: state.isFocused ? 'transparent' : 'transparent'
      }
  })
};

const GetChecklists= async()=>{
  let params = {
    page: tablePageIndex,
    limit: itemsPerPage,
    status: searchStatus,
    keyword: searchName,
    property_id:searchProperty,
    assignee_id:searchAssignee,
    company_id:searchCompany
};


    const checkist_data = await GetChecklistApi(jsonToUrlParams(params));
    console.log(checkist_data)
    let data_array:any=[]
    if(checkist_data.status==100){
      setDataCount(checkist_data.count)
        // for(let i=0;i<users_data.data.length;i++){
      
        //     let data_json={
        //         id:users_data.data[i].id,
        //         name:users_data.data[i].name,
        //         assignee:users_data.data[i].assignee_name,
        //         status:users_data.data[i].status,
        //         template_name:users_data.data[i].template_name,
        //         property_name:users_data.data[i].property_name,
        //         property:users_data.data[i].property_name,
        //         property_id:users_data.data[i].property_id,
        //         is_locked:users_data.data[i].is_locked,
        //         is_verified:users_data.data[i].is_verified,
        //     }
        //     data_array= [data_json,...data_array]

        // }
        console.log("vadad",data_array)
         setChecklistData(checkist_data.data)
        
    }
    
}

const GetProperties= async()=>{
  const property_data = await GetAllPropertyApi();
  console.log(property_data)

  if(property_data.status==100){
      setPropertyData(property_data.data)
      
  }
  
}


const GetUsers= async()=>{
  const users_data = await GetUsersApi();
  console.log(users_data)
  let data_array:any=[]
  if(users_data.status==100){

      console.log("vadad",data_array)
       setUserData(users_data.data)
      
  }
  
}


const handlePageChange = async (pageNumber: any) => {
  await setCurrentPage(pageNumber);
  tablePageIndex = pageNumber
  GetChecklists()
};

  const handleActionClick = (rowData: { [key: string]: any }) => {
    // handle the click event
    console.log('Button clicked for:', rowData);
    navigate('/checklist/'+rowData.id)
  };

  const handleActionClick2 = (rowData: { [key: string]: any }) => {
    // handle the click event
    console.log('Button clicked for:', rowData);
    navigate('/checklist-management/view/'+rowData.id)
  };

const renderActionButton = (row: { [key: string]: any }) => {

  return <div className="d-flex justify-content-around"><button className='ms-1 view-btn' onClick={() => handleActionClick2(row)}>Print</button>
  {type=="super" && <button className='ms-1 view-btn' onClick={()=>deleteChecklist(row['id'])}>Delete</button>}</div>;
};

const renderProgressBar=(width:any)=>{

  return <div className="progress" style={{height: "10px"}}>
  <div className="progress-bar" role="progressbar" style={{width: width+'%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
</div>
}


const userOptions = userData?.map((user:any) => ({
  value: user.id,
  label: `${user.first_name} ${user.last_name}`
}));


const companyOptions = companyData?.map((company:any) => ({
  value: company.id,
  label: company.name
}));


const propertyOptions = propertyData?.map((property:any) => ({
  value: property.id,
  label: property.name
}));


const resetBtn=()=>{
  setSearchAssignee('')
  setSearchName('')
  setSearchProperty('')
  setSearchStatus('')
  SetisReset(!isReset)
  setSearchCompany('')
}

const deleteChecklist=async(id:any)=>{

  const delete_value:any = await DeleteChecklistApi(id);
  console.log(delete_value)
  if(delete_value.status==100){
    toast.success("Successfully deleted checklist")
    GetChecklists()
  }

}

    return(
      <div className='main-content  fade-in'>
      <div className="row">
        <div className="col-12 col-md-8 col-lg-8 d-flex">
          <p className="breadcrumb pe-2">Management</p>
          <BsChevronCompactRight/>
          <p className="breadcrumb ps-2">Checklist Management</p>
        </div>

   {type !=="super" &&    <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-end">
        <div>
      <button type="button" className="create-checklist-btn"  onClick={()=>navigate('/checklist-management/create-checklist')}> <span className='pe-2'><img src={checklistbtn} /></span>
Create Checklist      </button>
        </div>
      </div>}
</div>


      <div className="filter-box mt-3">
        <div className="d-flex justify-content-between pb-2">
          <div className="row w-100">
            {/* {favourites.map((value)=>(
              <div className="col-2 me-lg-0 me-md-0 me-5"><span className="recently-viewed">{value} <BsX className="recent-cross" /></span></div>
            ))} */}

          </div>

          <div>
          <div className="d-flex">
      <button onClick={()=>SearchUsingFilters()} type="button" className="apply-btn me-2 " > <span className='pe-2'><img src={applybtn} /></span>
Apply     </button>

<span onClick={()=>resetBtn()} style={{cursor:'pointer'}}><img width={35} className="img-fluid" src={resetbtn}/></span>

        </div>
            </div>    
        </div>


        <div className="row filter-margin">
          <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Name</label>
              <input value={searchName} onChange={(e)=>setSearchName(e.target.value)} className="mt-1 filter-select" placeholder="Enter name" />

          </div>

            {/* user searchable checklist */}
          <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Assign To</label>
              <Select styles={customStyles}
        options={userOptions}
        value={userOptions && userOptions.find((option:any) => option.value === searchAssignee)}
        isClearable={true}
        onChange={(selectedOption:any) => setSearchAssignee(selectedOption ? selectedOption.value : '')}
        className="mt-1 filter-select"
        placeholder="Select Assignee"
    />
          </div>

          {/* property searchable checklist */}
          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">Property</label>
              <Select
    styles={customStyles}
    options={propertyOptions}
    value={propertyOptions && propertyOptions.find((option:any) => option.value === searchProperty)}
    isClearable={true}
    onChange={(selectedOption) => setSearchProperty(selectedOption ? selectedOption.value : '')}
    className="mt-1 filter-select"
    placeholder="Select Property"
/>
          </div>


          <div className="col-6 col-md-3 col-lg-3">
              <label  className="filter-label">State</label>
              <select value={searchStatus} onChange={(e)=>setSearchStatus(e.target.value)} className=" mt-1 filter-select">
              <option value="">Select status</option>
                <option value="open">Open</option>
                <option value="complete">Complete</option>
                <option value="in_progress">In Progress</option>
                <option value="draft">Draft</option>
     
              </select>
          </div>
        </div>

  {type == "super" &&     <div className="row filter-margin">
        <div className="col-6 col-md-3 col-lg-3">
              <label className="filter-label">Select Company</label>
              <Select styles={customStyles}
        options={companyOptions}
        value={companyOptions && companyOptions.find((option:any) => option.value === searchCompany)}
        isClearable={true}
        onChange={(selectedOption:any) => setSearchCompany(selectedOption ? selectedOption.value : '')}
        className="mt-1 filter-select"
        placeholder="Select Company"
    />
          </div>
        </div>}
      </div>

<div className="mt-3">
      <Table headers={headers} data={checklistData} renderButton={renderActionButton} keys={keys} isActionButton={true} isProgressBar={true} renderProgress={renderProgressBar} navigation="/checklist/"/>
      
      <Pagination itemsPerPage={itemsPerPage} count={dataCount} currentPage={currentPage} onPageChange={handlePageChange} />

      
      </div>



        </div>
        );

}

export default ChecklistManagement;
