
import { useEffect, useState } from "react";
import updateimg from "../../../assets/update-img.png"
import "./EditUsers.scss";
import { GetAllPropertyApi } from "../../../services/PropertyManagement.service";
import { UpdateUsersApi } from "../../../services/UserManagement.service";
import toast from "react-hot-toast";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import { BsFillDashCircleFill, BsFillPlusCircleFill } from "react-icons/bs";

type EditProps = {
    user_data: any;
    updateGet :()=>void;
  };

export const EditUsers = ({ user_data, updateGet }: EditProps) => {
    const [first_name,setFirstName] = useState(user_data.first_name);
    const [last_name,setLastName] = useState(user_data.last_name);
    const [role,setRole] = useState(user_data.role);
    const [email,setEmail] = useState(user_data.email);
    const [propertyData, setPropertyData] = useState<number[]>(user_data.property_id);
    const [type,settyperData] = useState(sessionStorage.getItem('user_type')||localStorage.getItem('user_type'));

       // State for the first toggle
       const [isLocked, setIsLocked] = useState(user_data && user_data.is_locked);

       // State for the second toggle
       const [isActive, setIsActive] = useState(user_data && user_data.status=="Active");

       const [isDisabled, setDisabled] = useState(false);
       const [isDisabled2, setDisabled2] = useState(false);

    const [property,setProperty] = useState([]);
    console.log(user_data)
    useEffect(() => {
        GetProperties();
    }, []);

    const GetProperties= async()=>{

        const property_data:any = await GetAllPropertyApi();
      
        if(property_data.status==100){
            console.log(property_data)

            setProperty(property_data.data)
            
        }
        
    }

    const updateData=async()=>{

        if(!first_name){
            return toast.error('First name is mandatory')
        }

        if(!last_name){
            return toast.error('Last name is mandatory')
        }

        if(!role){
            return toast.error('User role is mandatory')
        }

        
        if(!email){
            return toast.error('Email is mandatory')
        }





        // if(!propertyData && role!="Admin"){
        //     return toast.error('Property is mandatory')
        // }


        let updateData={
            first_name:first_name,
            last_name:last_name,
            email:email,
            user_role:role=="Admin"?'admin':role=="User"?'user':'',
            is_active:isActive,
            is_locked:isLocked,
            property_id:propertyData
        }

        if(email == user_data.email){
            delete updateData.email;
        }
        setDisabled(true)
        const update_response = await UpdateUsersApi(user_data.id,updateData)
        if(update_response){
            setDisabled(false)
            if(update_response.status==100){
                toast.success("Successfully updated user")
                updateGet()
            }else{
                toast.error("Error updating user details: "+update_response.message)
            }
        }


    }


    const resendEmail=async()=>{

        let updateData={
            resend_email:1,
        }
        setDisabled2(true)
        const update_response = await UpdateUsersApi(user_data.id,updateData)
        if(update_response){
            setDisabled2(false)
            if(update_response.status==100){
                toast.success("Successfully resent the email")
                updateGet()
            }else{
                toast.error("Error sending the email: " + update_response.message)
            }
        }


    }
    
    const handlePropertyChange = (index: number, value: string) => {
        console.log(value)
        const newPropertyData = [...propertyData];
        newPropertyData[index] = parseInt(value);
        console.log(newPropertyData)
        setPropertyData(newPropertyData);
    };

    const addPropertyDropdown = () => {
        const hasEmptyValue = propertyData.some((value) => value === null);
        
        if (hasEmptyValue) {
            toast.error('Property cannot be empty');
        } else {
            setPropertyData([...propertyData, 0]);
        }
    };

    const removePropertyDropdown = (index: number) => {
        const newPropertyData = propertyData.filter((_, i) => i !== index);
        setPropertyData(newPropertyData);
    };

    return(
        <div className="row">
            <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
                 <img className="img-fluid" src={updateimg} /> 
            </div>

            <div className="col-6 col-md-6 col-lg-6">
            <div>
                    <p className='viewuser-heading'>Update User Account</p>
                    <p className='viewuser-heading2'>Please edit your contact details to update account.</p>
                </div>


                <div className="pt-3 pb-3">
    <label className="input_label_style2">First Name</label>
    <input value={first_name} onChange={(e)=>setFirstName(e.target.value)} className=" mt-1 input_style_register" placeholder="First Name"/>
</div>

<div className="pb-3">
    <label className="input_label_style2">Last Name</label>
    <input value={last_name} onChange={(e)=>setLastName(e.target.value)} className=" mt-1 input_style_register" placeholder="Last Name"/>
</div>

<div className="pb-3">
    <label className="input_label_style2">User Role</label>
    <select value={role} onChange={(e)=>setRole(e.target.value)} className=" mt-1 input_style_register">
        <option value="">Select Role</option>
      {type != "Super" &&  <option value="User">User</option>}
        <option value="Admin">Admin</option>
    </select>
</div>

<div className="pb-3">
    <label className="input_label_style2">Email Address</label>
    <input value={email} onChange={(e)=>setEmail(e.target.value)} className=" mt-1 input_style_register" placeholder="Email Address"/>
</div>

{type !== "super" && <div className="pb-4">
    {/* <label className="input_label_style2">Select Property</label>
    <select value={propertyData} onChange={(e)=>setpropertyData(e.target.value)} className=" mt-1 input_style_register">
        <option value="">Select Property</option>
            {property && property && property.map((value:any)=>(
                <option key={value.id} value={value.id}>{value.name}</option>
            ))}
    </select> */}


    <div className="pb-3">
                        <label className="input_label_style2">Select Property</label>
                        <div className="col-2 d-flex align-items-center">
                                    {propertyData.length ==0  && (
                                        <div onClick={addPropertyDropdown}>
                                            <BsFillPlusCircleFill style={{ cursor: 'pointer' }} />
                                        </div>
                                    )}
                                </div>
                        {propertyData.map((data, index) => (
                            <div className="row mb-2" key={index}>
                                <div className="col-8">
                                    <select value={data} onChange={(e) => handlePropertyChange(index, e.target.value)} className=" mt-1 input_style_register">
                                        <option value="">Select Property</option>
                                        {property && property.map((value: any) => (
                                            <option key={value.id} value={value.id}>{value.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-2 d-flex align-items-center">
                                    {index === propertyData.length - 1  && (
                                        <div onClick={addPropertyDropdown}>
                                            <BsFillPlusCircleFill style={{ cursor: 'pointer' }} />
                                        </div>
                                    )}
                                </div>
                                <div className="col-2 d-flex align-items-center">
                                    {propertyData.length > 1 && (
                                       <div onClick={()=>removePropertyDropdown(index)}>
                                       <BsFillDashCircleFill  style={{ cursor: 'pointer',color:'red' }} />
                                   </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
</div>}


<div className='d-flex'>
                <div className="form-check form-switch pe-5">
              

                    <input checked={isLocked} onChange={()=>setIsLocked(!isLocked)} className="form-check-input lock" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    <label className='lock-txt' >Locked</label>
                    </div>

                    <div className="form-check form-switch">
                <label  className='active-txt'>Active</label>

                    <input checked={isActive} onChange={()=>setIsActive(!isActive)}  className="form-check-input active-user" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                    </div>
                </div>

<div>
{!user_data.is_verified &&        <SubmitButton
        isDisabled={isDisabled2}
        buttonText="Resend Email"
        onClickHandler={resendEmail}
        is_loading={isDisabled2}
        classname="resend-btn mt-3"
      />}
</div>

<div className="pt-3 d-flex justify-content-end">
    <button onClick={()=>updateGet()} className="discard-btn me-3">Discard</button>
    <SubmitButton
        isDisabled={isDisabled}
        buttonText="Save"
        onClickHandler={updateData}
        is_loading={isDisabled}
        classname="editsave-btn"
      />
</div>


            </div>
        </div>
    );


}